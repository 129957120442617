@tailwind base;
@tailwind components;
@tailwind utilities;

.grecaptcha-badge {
  visibility: hidden !important;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 72.2% 50.6%;
    --primary-foreground: 0 85.7% 97.3%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 72.2% 50.6%;
    --radius: 0.7rem;

    --chart-1: 347 77% 50%;
    --chart-2: 349 77% 62%;
    --chart-3: 350 80% 72%;
    --chart-4: 351 83% 82%;
    --chart-5: 352 83% 91%;
    --color-1: 0 100% 63%;
    --color-2: 270 100% 63%;
    --color-3: 210 100% 63%;
    --color-4: 195 100% 63%;
    --color-5: 90 100% 63%;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 72.2% 50.6%;
    --primary-foreground: 0 85.7% 97.3%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 72.2% 50.6%;

    --chart-1: 347 77% 50%;
    --chart-2: 349 77% 62%;
    --chart-3: 350 80% 72%;
    --chart-4: 351 83% 82%;
    --chart-5: 352 83% 91%;
    --color-1: 0 100% 63%;
    --color-2: 270 100% 63%;
    --color-3: 210 100% 63%;
    --color-4: 195 100% 63%;
    --color-5: 90 100% 63%;
  }
}

 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}



.primary-shadow {
  box-shadow: 0 0 10px hsl(var(--primary))
}




@keyframes glow {
  0%, 100% {
    box-shadow: 0 0 15px rgba(255, 200, 0, 0.7);
  }
  50% {
    box-shadow: 0 0 25px rgba(255, 200, 0, 0.7);
  }
}


@keyframes discord {
  0%, 100% {
    box-shadow: 0 0 10px rgba(120, 60, 245, 0.7);
  }
  50% {
    box-shadow: 0 0 15px rgba(120, 60, 245, 0.7);
  }
}


@keyframes telegram {
  0%, 100% {
    box-shadow: 0 0 10px rgba(0, 196, 255, 0.5);
  }
  50% {
    box-shadow: 0 0 15px rgba(0, 196, 255, 0.5);
  }
}

@keyframes work-ink {
  0%, 100% {
    box-shadow: 0 0 10px rgb(3, 140, 109);
  }
  50% {
    box-shadow: 0 0 15px rgb(3, 140, 109);
  }
}
